import React from 'react';
import './App.scss';
import UserMenu from './userMenu/UserMenu';
import { useAuth0 } from '@auth0/auth0-react';
import CardsPage from './cardsPage/cardsPage';

function App() {
  
  const { loginWithPopup, logout} = useAuth0();

  return (<>
    <div className="appContainer">
      <div className='appHeader'>
        <span>reinke.io</span>
        <div className='nav'>
          <UserMenu items={[{ label: "Profile", callback: () => { } }]} login={loginWithPopup} logout={() => logout({ logoutParams: { returnTo: window.location.origin } })} />
        </div>
      </div>
      <div className='appContent'>
        <CardsPage></CardsPage>
      </div>
      <div className='appFooter'>
        &copy;2023 Mathis Reinke | mathis@reinke.io
      </div>
    </div>
  </>);
}

export default App;
