import React, { useState } from "react";
import Button from "../button/Button";
import './UserMenu.scss';
import { useAuth0 } from "@auth0/auth0-react";

interface MenuItem {
    label: string,
    callback: ()=>void
}

interface UserMenuProps {
    items?: Array<MenuItem>,
    login: ()=>void,
    logout: ()=>void
}

function UserMenu({items,  login, logout}: UserMenuProps) {
    const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
    const {user, isAuthenticated} = useAuth0();
    const internalMenuItems = items || []
    
    const openUserMenu = () => {
        setIsUserMenuOpen(true);
    }

    const closeUserMenu = () => {
        setIsUserMenuOpen(false);
    }

    const internalLogout = () => {
        setIsUserMenuOpen(false);
        logout();
    }

    const internalLogin = () => {
        setIsUserMenuOpen(false);
        login();
    }

    return (<>
        {!isAuthenticated && <Button onClick={internalLogin}>login</Button>}
        {isAuthenticated && <Button onMouseEnter={openUserMenu} onMouseLeave={closeUserMenu}>
            Hello, {user?.name}
            <div className={'userMenu ' + (isUserMenuOpen? 'open': '')}>
                <div className='userMenu-container'>
                <ul>
                    <li className="spacer"/>
                    <li>
                        <Button onClick={internalLogout}>
                            Logout
                        </Button>
                    </li>
                    {internalMenuItems.map(item => <li>
                        <Button onClick={item.callback}>
                            {item.label}
                        </Button>
                    </li>)}
                    <li className="spacer"/>
                </ul>
                </div>
            </div>
        </Button>}
    </>)
}

export default UserMenu;