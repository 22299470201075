import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";

interface Item {
    title: string,
    text: string
}

function CardsPage(props: any) {
    const [cards, setCards] = useState<Array<Item>>();
    const { user, getAccessTokenSilently } = useAuth0();

    useEffect(() => {
        getAccessTokenSilently({
            authorizationParams: {
                audience: "https://reinke.io",
                scope: "access"
            }
        }).then(access_token => {
            axios.get("https://api.reinke.io/api", {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            })
                .then((value) => {
                    setCards(value.data);
                })
                .catch(err => {
                    setCards([{ title: "Insufficent permissions", text: "Please talk to the administrator" }])
                })
        })
            .catch(err => {
                setCards([]);
            })

    }, [user?.sub, getAccessTokenSilently])

    const renderCard = (item: Item, index: number) => {
        return (<div className='card ' key={index}>
            <span className='cardTitle'>{item.title}</span>
            <span className='cardContent'>{item.text}</span>
        </div>);
    }

    return (<>{cards?.map(renderCard)}</>)
}



export default CardsPage;